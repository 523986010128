/* 
Team Members Name - ( Last , First - StudentID ) 

1.  Patel, Deep  - 1002052935 
2.	Kaza, Goutham Kumar  â€“ 1002041906 
3.	Maturi, Giridhar  - 1002125755
4.	Mavillapally, Meghana  - 1002069225
5.	Nalabolu, Tharun Reddy  - 1002069440

*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
