body {
    font-family: Arial, sans-serif;
    background-color: #e0e0e0;
}

header {
    background-color: #e0e68c;
    color: #000;
    text-align: center;
    padding: 20px;
z-index: 2;
}

nav {
    width: 120px;
    float: left;
    background-color: #333;
    color: #fff;
    padding: 20px;
   position: relative; /* Add relative positioning */
    z-index: 3; 
}

nav a {
    display: block;
    color: #fff;
    text-decoration: none;
    margin-bottom: 92px; /* Reduce this margin to your desired spacing */
}

nav a:hover {
    color: #e0e68c;
}

/* Adjust the content container to avoid overlapping with the chat box */
.container {
    margin-left: 140px; /* Adjust this value */
    margin-right: 320px; /* Add margin on the right to accommodate the chat box */
    padding: 20px;
 z-index: 1;
}

.content {
    margin: 20px;
    
}

.text {
    margin-bottom: 20px;
}

footer {
    clear: both;
    background-color: #e0e68c;
    color: #000;
    text-align: center;
    padding: 10px;
     z-index: 2;
    
}

.help-box {
    border: 1px solid #ccc;
    padding: 80px;
}

.box-heading {
    font-weight: bold;
}

.box-input {
    width: 105%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}


.box-send {
    text-align: center;
}
/* Style for the login box */
.login-box {
    max-width: 400px; /* Set a maximum width for the login box */
    margin: 0 auto; /* Center the login box horizontally */
    padding: 20px;
   
    text-align: left; /* Align form content to the left */
}


.box-button {
    text-align: center;
}
.dashboard {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .section {
            border: 1px solid #ccc;
            padding: 20px;
            margin-bottom: 20px;
            text-align: center;
            background-color: #d0d9d2;
        }
.chat-box {
    border: 1px solid #ccc;
    padding: 20px;
    position: fixed; /* Keep the chat box fixed within the viewport */
    right: 20px; /* Position it on the right side */
    top: 50%; /* Center it vertically */
    transform: translateY(-50%); /* Center it vertically */
    width: 300px; /* Set the chat box width */
    background-color: #bdbfbe;
    z-index: 1; /* Ensure it's above other elements */
}


      
        .chat-title {
            font-weight: bold;
            text-align: center;
        }
        .chat-content {
            margin-top: 20px;
            max-height: 200px;
            overflow-y: scroll;
        }
        .chat-input {
            width: 80%;
            padding: 10px;
            margin-bottom: 10px;
        }
        .chat-button {
            text-align: center;
        }
        .close-button {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
        }

/* Apply styles to the container holding the images */
.image-container {
    display: flex; /* Display images in a row */
    justify-content: space-between; /* Distribute space between the images */
    align-items: center; /* Vertically align images */
    margin-top: 20px; /* Add top margin for spacing */
}

/* Style individual images */

.image-container img {
    width: 300px; /* Change this to your desired width */
    height: 200px; /* Change this to your desired height */
    
}


/* Default styles for all screen sizes */


/* Styles for screens smaller than 768px (tablets and mobile devices) */
@media (max-width: 768px) {
    nav {
        position: static; /* Reset position for smaller screens */
        float: none;
        width: 100%;
    }

    nav a {
        margin-bottom: 10px;
    }

    .container {
        margin-left: 20px; /* Reduce left margin for smaller screens */
        margin-right: 20px; /* Reduce right margin for smaller screens */
    }
    .image-container {
        flex-direction: column;
        align-items: center;
    }

    .image-container img {
        width: 100%;
        height: auto;
    }

    /* Responsive styles for the dashboard and chat boxes */
    .dashboard {
        flex-direction: column;
        align-items: center;
    }

    .section {
        width: 100%; /* Make sections full width on smaller screens */
    }
.chat-box {
        position: fixed;
        right: 20px;
        top: auto;
        bottom: 20px; /* Position chat box at the bottom for smaller screens */
        transform: none;
        width: calc(100% - 40px); /* Adjust the width for smaller screens */
        max-width: 300px; /* Limit the maximum width for smaller screens */
    }
    }
input {
    width: 100%; /* Make the input fields span the full width of the container */
    max-width: 100%; /* Prevent the input fields from exceeding the container's width */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
/* styles.css */

/* Container styles */
.container {
  margin: 20px;
}

/* Center the form */
.center {
  text-align: center;
}

/* Form styles */
.form-box {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px; /* Adjust the width to your preference */
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form input and label styles */
label {
  font-weight: bold;
  margin-top: 10px;
}

input[type="text"],
textarea {
  width: 100%; /* Make input fields 100% width */
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

/* Form message styles */
.form-message {
  color: #ff0000;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

/* Send button styles */
.send-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-box {
    max-width: 90%; /* Adjust for smaller screens */
  }
}
